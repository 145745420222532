import request from '@/utils/request';
const OSS = require('ali-oss');
const BASEURL = process.env.VUE_APP_API_AUTO;
export function getStsToken() {
  return request({
    url: `/autoUploadAd/sts/generateStsToken`,
    method: 'post',
    baseURL: BASEURL,
  });
}
