const OSS = require('ali-oss');
import { getStsToken } from '@/api/alioss';
const ossClient = new OSS({
  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
  region: 'oss-cn-beijing',
  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
  accessKeyId: 'LTAI4FwDhRbMMYXJ3GXZWXbg',
  accessKeySecret: 'Z36RVX6pgJiCOSvYzT4DAokMZkG2Xz',
  // 刷新临时访问凭证的时间间隔，单位为毫秒。
  refreshSTSTokenInterval: 300000,
  // 填写Bucket名称。
  bucket: 'gams-ad-beijing',
});

var aliossPutFile = async (fileName, file) => {
  let tstToken = await getStsToken()
    .then((res) => {
      if (res.code == 0) {
        return res.data;
      }
      return {
        expiration: '2024-08-03T04:30:55Z',
        securityToken:
          'CAISiAN1q6Ft5B2yfSjIr5fwG/zb3JF45fO/N23n12s6NPdDufKf1Tz2IHhMfnduAuAZt/8/nmpS6vcTlqZ6R4QATFHYao5t9pBQ+A/5/WQsZzXvv9I+k5SANTW56XeZtZagj4ybIfrZfvCyESem8gZ43br9cxi7QlWhKufnoJV7b9MRLGbaAD1dH4UUXDkAzvUXLnzML/2gHwf3i27LdipStxF7lHl05NbpoLmV4QGMi0bhmK1H5db+K52/KsBgeYx/XtGn3OFwFNzI2zUC7ANRpuUkzv5L8DLc+9iRRkJR7hOUM+fStZ9NVFYhOPRgQPMc9KiiyKQp57Dp+t6pm0oXD4Z8SD/CQY2s+szAFd6yO8wxHbSBYCyViI7Rbcmq6lh0OyhBaFIbYb0oJmQ1AAEwTXTHMqug8VzauIpcjmgQScjcuLUCLDWAlbLiTzDnyCVHy6vW27EW2+I55bNnHH3cWutL2qUAP19rD7qfTYd/AFz7jcatGam2Kl2c/qEnU42Kl5ot+fPPN+2XNhqAAZA6Y2iCyORP6DiDayD1sKc2DlHjEmiWhBNN2twlaqbQFHjUFitB1TV9pDIHlND6xGBlCdAO5igVhAIh0AYkJ2+u86zmqgLnwagME9YtbKmY668xbZAkbcZTxVi3UbvWXqMUB9EAe2NiDovBRYaJn/qC9p0S3ETK6qwxQhHL86+PIAA=',
        accessKeySecret: '5pkwbboZyxx9ZLZ2R5mpKG9uCogdRvZsta5poHbaHbmm',
        accessKeyId: 'STS.NTEPFo1NYR1T5KV3ko8xoV747',
        host: 'https://gamsad.giikin.cn',
      };
    })
    .catch((res) => {
      return {
        expiration: '2024-08-03T04:30:55Z',
        securityToken:
          'CAISiAN1q6Ft5B2yfSjIr5fwG/zb3JF45fO/N23n12s6NPdDufKf1Tz2IHhMfnduAuAZt/8/nmpS6vcTlqZ6R4QATFHYao5t9pBQ+A/5/WQsZzXvv9I+k5SANTW56XeZtZagj4ybIfrZfvCyESem8gZ43br9cxi7QlWhKufnoJV7b9MRLGbaAD1dH4UUXDkAzvUXLnzML/2gHwf3i27LdipStxF7lHl05NbpoLmV4QGMi0bhmK1H5db+K52/KsBgeYx/XtGn3OFwFNzI2zUC7ANRpuUkzv5L8DLc+9iRRkJR7hOUM+fStZ9NVFYhOPRgQPMc9KiiyKQp57Dp+t6pm0oXD4Z8SD/CQY2s+szAFd6yO8wxHbSBYCyViI7Rbcmq6lh0OyhBaFIbYb0oJmQ1AAEwTXTHMqug8VzauIpcjmgQScjcuLUCLDWAlbLiTzDnyCVHy6vW27EW2+I55bNnHH3cWutL2qUAP19rD7qfTYd/AFz7jcatGam2Kl2c/qEnU42Kl5ot+fPPN+2XNhqAAZA6Y2iCyORP6DiDayD1sKc2DlHjEmiWhBNN2twlaqbQFHjUFitB1TV9pDIHlND6xGBlCdAO5igVhAIh0AYkJ2+u86zmqgLnwagME9YtbKmY668xbZAkbcZTxVi3UbvWXqMUB9EAe2NiDovBRYaJn/qC9p0S3ETK6qwxQhHL86+PIAA=',
        accessKeySecret: '5pkwbboZyxx9ZLZ2R5mpKG9uCogdRvZsta5poHbaHbmm',
        accessKeyId: 'STS.NTEPFo1NYR1T5KV3ko8xoV747',
        host: 'https://gamsad.giikin.cn',
      };
    });
  let ossClient = new OSS({
    accessKeyId: tstToken.accessKeyId,
    accessKeySecret: tstToken.accessKeySecret,
    stsToken: tstToken.securityToken,
    // 将<YOUR_BUCKET>设置为OSS Bucket名称。
    bucket: 'gams-ad-shenzhen',
    // 将<YOUR_REGION>设置为OSS Bucket所在地域，例如region: 'oss-cn-hangzhou'。
    region: 'oss-cn-shenzhen',
    secure: true,
  });
  return new Promise(async (resolve, reject) => {
    try {
      await ossClient.put(fileName, file);
      const fileUrl = `${tstToken.host}/${fileName}`;
      resolve(fileUrl);
    } catch (error) {
      reject(error);
    }
  });
};
export { ossClient, aliossPutFile };
